import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

const config = {
  projectId: process.env.REACT_APP_SANITY_PROJECTID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  useCdn: false,
  apiVersion: '2024-07-24'
};

const client = createClient(config);

const getData = async (type, env ) => {
  try {
    const query = `*[_type == "${type}" && _id == $id][0]`;
    const params = { id: env };
    const data = await client.fetch(query, params);

    return data;
  } catch (error) {
    console.error('Sanity fetch error:', error);
    throw error;
  }
}

export const getAcuData = async () => getData('acupuncture', process.env.REACT_APP_SANITY_ACU_DOC_ID);
export const getContactData = async () => getData('contact', process.env.REACT_APP_SANITY_CONTACT_DOC_ID);
export const getHerbalMedicineData = async () => getData('herbal-medicine', process.env.REACT_APP_SANITY_HERBAL_MEDICINE_DOC_ID);
export const getHomeData = async () => getData('home', process.env.REACT_APP_SANITY_HOME_DOC_ID);
export const getMAData = async () => getData('martial-arts', process.env.REACT_APP_SANITY_MARTIAL_ARTS_DOC_ID);


const builder = imageUrlBuilder(client);

export const buildURL = (source) => builder.image(source);
