import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar: FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-links">
                <NavLink to='/' className="logo">
                    <div>
                        <img src='dragon_test.svg' alt="Logo" />
                        <p>DRAGON WELLNESS</p>
                    </div>
                </NavLink>
                <NavLink to="/about" className={({ isActive}) => isActive ? 'navbar-links active' : ''}>About</NavLink>
                <NavLink to="/acupuncture" className={({ isActive}) => isActive ? 'navbar-links active' : ''}>Acupuncture</NavLink>
                <NavLink to="/herbal-medicine" className={({ isActive}) => isActive ? 'navbar-links active' : ''}>Herbal Medicine</NavLink>
                <NavLink to="/martial-arts" className={({ isActive}) => isActive ? 'navbar-links active' : ''}>Martial Arts</NavLink>
                <NavLink to="/contact" className={({ isActive}) => isActive ? 'navbar-links active' : ''}>Contact</NavLink>
            </div>
            <div className='dropdown'>
                <div className='logo'>
                    <Link to='/'><img style={{ margin: '5px' }} src='dragon_test.svg' alt="Logo" /></Link>
                </div>
                <div className="hamburger" onClick={toggleDropdown}>
                    &#9776;
                </div>
            </div>
            <div className={`dropdown-content ${isOpen ? 'show' : ''}`}>
                <Link to="/about" onClick={toggleDropdown}>About</Link>
                <Link to="/acupuncture" onClick={toggleDropdown}>Acupuncture</Link>
                <Link to="/herbal-medicine" onClick={toggleDropdown}>Herbal Medicine</Link>
                <Link to="/martial-arts" onClick={toggleDropdown}>Martial Arts</Link>
                <Link to="/contact" onClick={toggleDropdown}>Contact</Link>
            </div>
        </nav>
    );
};

export default Navbar;
