import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { buildURL, getMAData } from '../sanity';
import '../styles/MartialArts.css';

const MA: FC = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getMAData();
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  // return (
  //   <div className="ma-container">
  //     {data ? (
  //       <div>
  //         {data.image && <img src={buildURL(data.image.asset._ref).url()} alt={data.Header} className="ma-image" />}
  //         <p className="ma-text">{data.tagline}</p>
  //       </div>
  //     ) : (
  //       <p>No data found</p>
  //     )}
  //   </div>
  // );
  return (
    <div className="home-container">
        {data ? (
            <div>
                <h1>{data.Header}</h1>
                <div className="image-grid">
                    <Link to='/acupuncture'>
                        <div className="grid-item">
                            <img src="dolphin.jpeg" alt="Acupuncture" />
                            <div className="overlay-text">Acupuncture</div>
                        </div>
                    </Link>
                    <Link to='/herbal-medicine'>
                        <div className="grid-item">
                            <img src="dolphin.jpeg" alt="Herbal Medicine" />
                            <div className="overlay-text">Herbal Medicine</div>
                        </div>
                    </Link>
                    <Link to='/martial-arts'>
                        <div className="grid-item">
                            <img src="dolphin.jpeg" alt="Martial Arts" />
                            <div className="overlay-text">Martial Arts</div>
                        </div>
                    </Link>
                </div>
                {/* <p>{data.tagline}</p> */}
            </div>
        ) : (
            <p>No data found</p>
        )}
    </div>
);
};

export default MA;
