import { FC, useEffect, useState } from 'react';
import { buildURL, getAcuData } from '../sanity';

const Acupuncture: FC = () => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
            const result = await getAcuData();
            setData(result);
            setLoading(false);
            } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    console.log(`Data: ${data}`);

    return (
        <div className="home-container">
            {data ? (
                <>
                    <div>
                        <h1>{data.Header}</h1>
                    </div>
                    <div className="image-grid">
                        <div className="grid-item"><img src="dolphin.jpeg" alt="Image 1" /></div>
                        <div className="grid-item"><img src="dolphin.jpeg" alt="Image 2" /></div>
                        <div className="grid-item"><img src="dolphin.jpeg" alt="Image 3" /></div>
                    </div>
                    <div>
                        <p style={{ marginTop: '20px', lineHeight: '1.5' }}>{data.services}</p>
                        <h3>{data.cta}</h3>
                        <a
                            href={`mailto:${data.email}`}
                            style={{ color: '#088F8F', textDecoration: 'none' }}
                        >
                            {data.email}
                        </a>
                    </div>
                </>
            ) : (
                <p>No data found</p>
            )}
            
        </div>
    );
};

export default Acupuncture;
