import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './components/About';
import MA from './components/MartialArts';
import Acupuncture from './components/Acupuncture';
import HerbalMedicine from './components/HerbalMedicine';
import Contact from './components/Contact';
import Navbar from './components/Navbar';
import Home from './components/Home';
import './App.css';

const App: React.FC = () => {
    return (
        <Router>
            <div className="App">
                <Navbar />
                <header className="App-header">
                    <div className="App-content">
                        <Routes>
                            <Route path="/about" element={<About />} />
                            <Route path="/acupuncture" element={<Acupuncture />} />
                            <Route path="/herbal-medicine" element={<HerbalMedicine />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/martial-arts" element={<MA />} />
                            <Route path="/" element={<Home />} />
                        </Routes>
                    </div>
                </header>
            </div>
        </Router>
    );
};

export default App;
